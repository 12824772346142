/*---///////////////////////////////  GLOBAL APP  STYLES //////////---*/
html {
  height: 100%;
}
body {
  height: 100%;
  background-color: #f6f6f6;
}
.wrapper {
  margin: 10px 15px;
}

.input-text {
  background: #f1f1f1;
}

.title-h1 {
  font-size: 24px;
  font-weight: 400;
}

.title-h2 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.title-h3 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
}

.error-msg {
  font-weight: 700;
  font-size: 15px;
  color: rgb(203, 12, 60);
  margin: 0;
}

.cta-primary {
  background: rgb(203, 12, 60);
  background: linear-gradient(
    65deg,
    rgba(203, 12, 60, 1) 13%,
    rgba(203, 94, 33, 1) 52%,
    rgba(190, 179, 17, 1) 100%
  );
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: rgb(203, 12, 60);
    background: linear-gradient(
      65deg,
      rgba(203, 12, 60, 1) 25%,
      rgba(203, 94, 33, 1) 65%,
      rgba(190, 179, 17, 1) 100%
    );
  }
}
.cta-secondary {
  background: #801744;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: #a0282a;
  }
}

.cta-tertiary {
  background: #fff;
  text-transform: uppercase;
  font-size: 15px;
  color: #801744;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 1px;
  border: 2px solid #801744;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: #f9f4f6;
  }
}

.cta-small-primary {
  background: #801744;
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  padding: 6px 9px;
  line-height: 13px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: #992d5c;
  }
}
.cta-small-secondary {
  background: #ca9851;
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  padding: 6px 9px;
  line-height: 13px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: #daa760;
  }
}
.cta-small-tertiary {
  background: #988c7a;
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  padding: 6px 9px;
  line-height: 13px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: #aea28f;
  }
}

.checkmark {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #ddd;
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    transform: rotate(45deg);
    width: 8px;
    height: 12px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid #fff;
  }
}

.is-valid .checkmark {
  background: rgb(110 181 110) !important;
  &::after {
    border-bottom: 4px solid #fff !important;
    border-right: 4px solid #fff !important;
  }
}

.back-bt {
  background: #ca9851;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;

  .left-arrow {
    transform: rotate(-45deg);
    width: 12px;
    height: 12px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    position: relative;
    left: 2px;
  }

  &:hover {
    background: #daa760;
  }
}

.upload-image {
  text-align: center;
  margin-top: 15px;
}

.upload-image + img {
  max-height: 450px;
}
.close-modal {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  opacity: 1;
  z-index: 10;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #801744;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1rem;
  outline: none;
  padding: 6px 14px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;

  &:hover {
    background-color: #9e2b5c;
  }
}

.modal {
  background: #fff;
  border-radius: 8px;
  margin: 30px;
  padding: 30px;
  max-width: 550px;
  width: 100%;

  &.new-language,
  &.new-card {
    align-self: flex-start;
    position: relative;
    top: 70px;
  }

  .title-h1 {
    text-align: center;
    line-height: 30px;
  }

  .section-cta {
    display: flex;
    margin-top: 60px;
    max-width: 800px;

    .create-bt {
      margin-left: 10px;
      flex: 1;
      text-align: center;
    }

    .cancel-bt {
      margin-right: 10px;
      flex: 1;
      text-align: center;
    }
  }
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  &.timeout {
    background: #c4c4c4;
    z-index: 40;
  }
}

@media screen and (min-width: 750px) {
  .modal {
    margin: 70px;
  }
}

.language-section {
  margin-bottom: 15px;
  h3 {
    margin: 0 15px 10px 0;
    display: flex;
  }

  select {
    padding: 6px 15px 6px 8px;
    font-size: 16px;
    border: 2px solid #d4c0c8;
    color: #801744;
    font-weight: 700;
  }
}

/*---///////////////////////////////  APP STYLES //////////---*/

.app-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  background: #fff;
  z-index: 100;

  .right-section {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .logo {
    width: 170px;
    cursor: pointer;
  }
  .logout {
    width: 25px;
    cursor: pointer;
    margin-left: 15px;
  }

  &::after {
    display: block;
    background: url("./assets/images/bg-line-header.png");
    content: "";
    width: 100%;
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
  }
}

.app-login {
  .title-h1 {
    text-align: center;
    margin-top: 70px;
  }
  .release {
    text-align: right;
    line-height: 26px;
    margin-bottom: 30px;
    font-size: 13px;
    color: #a2a2a2;
    margin-top: 180px;
    margin-right: 3px;
  }
  .title-creator {
    text-align: center;
    font-size: 25px;
    margin-top: 70px;
  }
  .form-login {
    margin: 20px auto 0 auto;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0px 12px 4px #0000000f;
    background: #fff;
  }
  .input-text {
    background: #f1f1f1;
    width: 100%;
    padding: 10px;
    font-size: 19px;
  }
  .section {
    margin-bottom: 20px;
  }
  label {
    font-size: 15px;
  }
  .cta-primary {
    margin: 20px auto 0 auto;
    display: block;
  }
  .error {
    font-size: 14px;
    color: red;
    font-weight: bold;
    margin: 0;
  }
}

.app-dashboard {
  padding-top: 30px;

  .bt-create {
    display: inline-block;
    cursor: pointer;

    img {
      margin: auto;

      padding: 10px;
      height: 70px;
    }
  }
  .title-h2 {
    margin: 30px 0 10px 0;
  }
  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    padding-bottom: 50px;
  }
  .wine-card {
    box-shadow: 0 0px 12px 4px #0000000f;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    width: calc(50% - 10px);
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
    width: 100%;
    position: relative;

    .name {
      margin-top: 8px;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0;
    }

    .details {
      text-align: center;
      margin-top: 6px;
    }
    .publish {
      text-align: center;
      margin-top: 10px;
    }
    .preview {
      text-align: center;
      margin-top: 10px;
    }
    .wine-card-infos {
      .cta-section {
      }
    }
    .card-lang {
      position: absolute;
      z-index: 10;
      background: #801744;
      display: flex;
      align-items: center;
      color: #fff;
      top: 0;
      padding: 6px 12px;
      font-weight: 700;
      font-size: 15px;

      .flag {
        margin-right: 5px;
      }
    }
  }

  .add-language-card {
    background: #801744;
    color: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s;

    img {
      width: 40%;
      margin-bottom: 20px;
    }

    &:hover {
      background: #9e2b5c;
    }
  }

  .thumbnail {
    text-align: center;
    background: #ece6e0;
    border-radius: 4px;
    padding: 4px;
    position: relative;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .thumbnail-img {
      margin: auto;
      max-width: 100%;
    }
    .delete-bt {
      background: #fff;
      width: 32px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 15;
      padding: 5px;
      border: 3px solid #ece6e0;
      transition: all 0.3s;
      cursor: pointer;
      img {
        width: 100%;
        transition: all 0.3s;
        opacity: 0.7;
      }

      &:hover {
        border: 3px solid #801744;

        img {
          opacity: 1;
        }
      }
    }
  }

  &.is-details {
    .error-msg{
      position: relative;
      bottom: 25px;
    }

    .form-name{
      display: flex;
      margin: 35px 0 30px 0;
      min-height: 45px;
      justify-content: space-between;
      align-items: center;
      .input-text{
        pointer-events: none;
        font-size: 20px;
        padding: 5px;
        padding-left: 10px;
        margin-right: 10px;
      }

      &.edit{
        .input-text{
          pointer-events:inherit;
          background: #fff;
          border: 2px solid #d4c0c8;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 350px) {
  .app-dashboard .wine-card {
    width: calc(50% - 10px);
  }
}

@media screen and (min-width: 500px) {
  .app-dashboard .wine-card {
    width: calc(33% - 10px);
  }
}
@media screen and (min-width: 750px) {
  .app-dashboard .wine-card {
    width: calc(25% - 10px);
  }
}
@media screen and (min-width: 1000px) {
  .app-dashboard .wine-card {
    width: calc(20% - 10px);
  }
}

.app-card {
  padding-top: 20px;

  h1 {
    margin-bottom: 20px;
  }

  .language-section {
    .lang {
      display: flex;
      align-items: center;
      margin-left: 9px;
    }
    .flag {
      margin-right: 5px;
    }
  }

  .name-section {
    .input-text {
      background: #fff;
      border: 2px solid #d4c0c8;
      color: #801744;
      padding: 8px;
      font-weight: 700;
      font-size: 18px;
      width: 100%;
      max-width: 960px;

      &::placeholder {
        color: #9d9d9d;
      }
    }
  }

  &.is-edit .name-section {
    display: flex;

    h2 {
      font-size: 20px;
      margin-left: 5px;
    }
   
 
  }

  .title-categories {
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    margin-right: -15px;
    max-width: 980px;

    li {
      list-style-type: none;
      box-shadow: 0 0px 12px 4px #0000000f;
      background: #fff;
      border-radius: 8px;
      margin: 0;
      text-align: center;
      width: 100px;
      margin-right: 15px;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      padding: 15px 10px 10px 10px;
      border: 1px solid #d4c0c8;
      transition: all 300ms;
      position: relative;
      cursor: pointer;

      &:hover {
        transform: scale(1.08);
      }

      .checkmark {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      img {
        height: 36px;
        margin: auto auto 5px auto;
      }

      &.menu {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e4e8f1;;
        color: #2a3d6b;
        font-size: 17px;
        line-height: 20px;
        border-color: #919bc4;
        min-height: 90px;

        .checkmark {
          background: #f6f6ff;

          &::after {
            border-bottom: 4px solid #d6d5e9;
            border-right: 4px solid #d6d5e9;
          }
        }
      }
    }
  }

  .section-cta {
    display: flex;
    margin-top: 60px;
    max-width: 800px;

    .create-bt {
      margin-left: 10px;
      flex: 1;
      text-align: center;
    }

    .cancel-bt {
      margin-right: 10px;
      flex: 1;
      text-align: center;
      position: relative;

      img {
        height: 20px;
        position: absolute;
        top: calc(50% - 10px);
        left: 10px;
      }
    }
  }

  @media screen and (min-width: 300px) {
    .categories li {
      width: calc(50% - 15px);
    }
  }

  @media screen and (min-width: 500px) {
    .categories li {
      width: calc(33% - 15px);
    }
  }
  @media screen and (min-width: 750px) {
    .categories li {
      width: calc(25% - 15px);
    }
  }
  @media screen and (min-width: 1000px) {
    .categories li {
      width: calc(20% - 15px);
      max-width: 180px;
    }
  }
}

.modal-new-card {
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  .name-section {
    margin-bottom: 20px;

    .input-text {
      background: #fff;
      border: 2px solid #d4c0c8;
      color: #801744;
      padding: 8px;
      font-weight: 700;
      font-size: 18px;
      width: 100%;

      &::placeholder {
        color: #9d9d9d;
      }
    }
  }
}
.modal-confirmation {
  .modal {
    padding-top: 60px;
  }
}

.app-menu {
  .input-text {
    background: #fff;
    border: 2px solid #d4c0c8;
    color: #801744;
    padding: 8px;
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    max-width: 960px;

    &::placeholder {
      color: #9d9d9d;
    }
  }

  .validate-section {
    display: flex;
    padding-bottom: 100px;
    max-width: 800px;
    margin: 40px auto 0 auto;
    width: calc(100% - 20px);

    .validate-bt {
      margin-left: 10px;
      flex: 1;
      text-align: center;
    }

    .cancel-bt {
      margin-right: 10px;
      flex: 1;
      text-align: center;
    }
  }

  .title-h2 {
    margin-top: 40px;
    text-align: center;
  }

  .menuList {
    list-style-type: none;
    margin: 30px auto;
    max-width: 800px;
    li {
      display: flex;
      margin-bottom: 8px;
    }
    .error-msg{
     position: relative;
     bottom: 5px;
     margin-left: 51px;
    }

    .icon {
      background: #fff;
      width: 50px;
      height: 50px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #d4c0c8;
      margin-right: 5px;

      img {
        width: 26px;
      }
    }
  }
}

/*---///////////////////////////////  VIEWS CARD STYLE //////////---*/
.page-content {
  .wine-top-infos {
    .input-text {
      text-align: center;
    }
  }
  .content-top .input-text {
    text-align: center;
  }
  .input-text {
    background: #fff;
    border: 2px solid #d4c0c8;
    color: #801744;
    padding: 4px;
    font-weight: 700;

    &::placeholder {
      color: #9d9d9d;
    }

    &:focus {
      border: 1px solid #801744;
      box-shadow: 0 0 5px 5px #0000000d;
    }
  }

  .textarea {
    background: #fff;
    border: 1px solid #d4c0c8;
    color: #000;
    padding: 4px;
    width: 100%;
    min-height: 70px;

    &::placeholder {
      color: #9d9d9d;
    }

    &:focus {
      border: 1px solid #801744;
      box-shadow: 0 0 5px 5px #0000000d;
    }
  }

  .bt-certificate .input-text {
    font-size: 15px;
    text-align: center;
  }
}

#page {
  .error-msg {
    display: block;
  }
  #error-image {
    text-align: center;
  }

  .validate-section {
    display: flex;
    padding-bottom: 100px;
    max-width: 800px;
    margin: 40px auto 0 auto;
    width: calc(100% - 20px);

    .validate-bt {
      margin-left: 10px;
      flex: 1;
      text-align: center;
    }

    .cancel-bt {
      margin-right: 10px;
      flex: 1;
      text-align: center;
    }
  }
}
