
/*---///////////////////////////////   GENERIC STYLES //////////---*/ 


@font-face { font-family: Pangram; src: url('./assets/webfonts/Pangram-Regular.otf'); } 
@font-face { font-family: Pangram; font-weight: bold; src: url('./assets/webfonts/Pangram-Bold.otf');}

* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	outline: none;
	font-size-adjust: none;
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translate3d(1,1,1);
    transform:translate3d(1,1,1);    
    text-rendering: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus {outline: none;}
*,*:after,*:before {	
    box-sizing: border-box;	
    padding: 0;	
    margin: 0;
}

*{
    font-family:'Pangram';
}
body{
	margin: 0;
	padding: 0;
	overflow-x:hidden;
    font-size:13px;
    line-height:23px;
    font-weight:400;
    background-color:#FFFFFF;
}

::-webkit-scrollbar{display: none;}
::selection {background-color:#CCC; color:#000;}
div, a, p, img, blockquote, form, fieldset, textarea, input, label, iframe, code, pre {
	display: block;
	position:relative;
}

textarea, input {outline: none;}
input[type="submit"]{appearance:none;   -webkit-appearance: none;}
input{border-radius: 0!important; -webkit-border-radius:0px!important; display:inline-block;}
textarea{-webkit-appearance: none!important; border-radius: 0!important; -webkit-border-radius:0px!important;}
label a{display:inline-block;}
.no-click{pointer-events:none!important;}
.no-transition{transition:all 0ms ease!important;}
/*---Typography---*/ 
/*----------------*/ 
a{color:#2980b9;}
p > a {	display: inline; text-decoration: none;}
p > a:hover{text-decoration: underline;}
p > span {display: inline;}
p strong{font-size:13px; font-weight:500;}
span > a {display: inline;}
a > span {display: inline;}
a {text-decoration: none;}
a:hover {text-decoration: none;}
strong a, em a{display:inline;}
h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {display: inline;}
table {border-collapse: separate; border-spacing: 0; width: 100%; clear: both; margin-bottom: 27px;}
th {vertical-align: middle; font-weight: bold;}
tr {line-height: 18px;}
td {text-align: center; padding:9px 0px 9px 0px; line-height: 18px;	vertical-align: middle;}
.table tr.even td{background: #efefef;}
th{	border-bottom: 1px solid rgba(255,255,255,0.1); color: #1f1f1f; line-height:45px;	background-color: #f6f6f6;}
td{ color: #666;background-color: #fdfdfd;}
table{background-color: #f6f6f6; font-size:12px;}
ul, ol{color:#666666; font-size:13px; margin-left:20px;}
ul {margin-bottom: 30px;}
ul ul{margin-bottom:5px;}
ol ol{margin-bottom:5px;}
ol {margin-bottom: 30px;}
li {line-height: 24px;	margin-bottom:5px;}
ol > li:last-child, ul > li:last-child {margin-bottom:0px;}
sub, sup{font-size: 50%; line-height: 0;  position: relative;  vertical-align: baseline;}
sup{top: -15px;} sub{bottom: 0px;}
sup50{top: -100px}; 
i{min-width:12px;}
.disabled{display:none!important;}
.stop-scroll{overflow: hidden!important;}
.no-click{pointer-events:none;}
.no-border{border:none!important;}
.responsive-image{display:block; height:auto; max-width:100%; overflow:hidden;}
.responsive-image2{margin: auto !important; width:50%}

.round-image{border-radius:100%;}

/*Typography*/
p{line-height: 26px; margin-bottom: 30px; font-size:13px; color:#6c6c6c;}
p strong{color:#1f1f1f;}
h1, h2, h3, h4, h5, h6{
    margin-bottom:5px;
    letter-spacing:-0.9px;
    font-weight:700;
    font-family: 'Pangram';
}
h1 *, h2 *, h3 *, h4 *, h5 *, h6 *{
    font-family: 'Pangram';
}
hr {
    margin: 0px 15px 30px 15px;
    clear: both;
    display: block;
    width: 90%;
    background-color: #aaa7a8;
    height: 1px;
}



/*---///////////////////////////////  ICONS //////////---*/ 

.div-icon-accueil{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 7px !important;
    margin-bottom: 5px;
    padding-left: 10px !important;
}

.div-icon-propriete{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
    margin-bottom: 5px;
    padding-left: 1px !important;
}

.div-icon-services{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
    margin-bottom: 5px;
    padding-left: 5px !important;
}

.div-icon-tracabilite{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
    margin-bottom: 5px;
    padding-left: 5px !important;
}

.div-icon-documents{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
    margin-bottom: 5px;
    padding-left: 2px !important;
}

.icon_accueil {
    background: url('./assets/images/ico_accueil.png');
}

.icon_baccueil {
    background: url('./assets/images/bico_accueil.png');
}

.icon_propriete {
    background: url('./assets/images/ico_propriete.png');
}


.icon_services {
    background: url('./assets/images/ico_services.png');
}

.icon_conseils {
    background: url('./assets/images/ico_conseils.png');
}

.icon_tracabilite {
    background: url('./assets/images/ico_tracabilite.png');
}

.icon_documents {
    background: url('./assets/images/ico_documents.png');
}

.icon_track {
    background: url('./assets/images/track.png');
}




/*---///////////////////////////////  FOOTER ?ENU  //////////---*/ 

.footer-menu-static{position:relative!important;}
.footer-menu{
    z-index:96;
    position:fixed;
    bottom:0px;
    left:0px;
    right:0px;
    height:calc(60px + (constant(safe-area-inset-bottom)));
    height:calc(60px + (env(safe-area-inset-bottom)));
    border-top:solid 1px rgba(0,0,0,0.03);
    transition:transform 350ms ease;
    background-color:#FFF;
    box-shadow:0 -5px 15px 0 rgba(0,0,0,0.09)!important;
}

.footer-menu a{float:left;text-align: center;padding-top: 6px;}

.selected-item{
    border-top: #801744 solid 3px;
}
.footer-6-icons a{width:16.5%}
.footer-5-icons a{width:20%}
.footer-4-icons a{width:25%}
.footer-3-icons a{width:33.3%}
.footer-2-icons a{width:50%}



.footer-menu a i{
    display:inline-block;
    width: 20px;
    height: 20px;
    text-align:center;
    background-repeat: no-repeat;
    background-position: center;
}

.footer-menu span{
    font-size:8px;
    display:block;
    text-align:center;
    color:#666;
    margin-top:-2px;
}

.footer-menu-no-text span{opacity:0;}
.footer-menu-no-text i{padding-top:20px!important; font-size:17px!important;}
.footer-menu-no-text{
    height:calc(60px + (constant(safe-area-inset-bottom)))!important;
    height:calc(60px + (env(safe-area-inset-bottom)))!important;
}


.footer-menu-center-icon .active-nav span{display:none;}
.footer-menu-center-icon i{margin-top:0px; font-size:16px!important;}
.footer-menu-center-icon .active-nav i{
    box-shadow:0 5px 15px 0 rgba(0,0,0,0.2)!important;
    color:#FFF!important;
    width:42px;
    height:42px;
    margin:7px auto 0px auto!important;
    border-radius:45px;
    line-height:11px;
}
.footer-menu-center-icon.footer-menu-no-text .active-nav i{padding-top:16px!important;}



.page-content{
    z-index:95;
    background-color:#F6F6F6;
    overflow-x:hidden;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

.page-footer-menu .page-content{
    padding-bottom:calc(60px + (constant(safe-area-inset-bottom)));
    padding-bottom:calc(60px + (env(safe-area-inset-bottom)));
}



/*---///////////////////////////////  CONTENT  //////////---*/ 




.content-box{
    margin: 0px 15px;
}
.content-box h6, .content-box h6 .input-text {
    font-size: 15px;
    color: #801744;
    line-height: 20px;
    font-weight: 700;
}

.content-box p{
    font-size: 12px;
    line-height: 24px;
    color: #000;
}
.content-image{
    overflow: hidden;
    margin: 10px 15px 30px 15px;
}
.content-image img{
    border-radius: 10px;
    max-width: 100%;
    margin: auto;
    max-width: 700px;
    max-height: 500px;
}
.content-top{
    margin: 20px 15px 30px 15px;
    background-color: #FFF;
    border-radius: 20px;
    padding: 30px 10px 0px 10px;
}
.content-top .wine-top-infos{
    margin-top: 0;
}
.content-top .caption-image{
    text-align: center;
    padding: 15px;

}
.content-top .caption-image img{
    width: 15%;
    max-width: 50px;
    display: inline-block;
}
.content-top .caption-image h5{
    color: #D20C40;
    font-size: 22px;
    font-weight: 100;
}

.content-top.is-documents .caption-image h5{
    color: #BB9323;
}
.content-top.is-traceability .caption-image h5{
    color: #CB5822;
}

.wine-top-infos{
    text-align: center;
    margin-top: 50px;
}

.wine-top-infos .wine-name{
    color: #801744;
    font-size: 20px;
    line-height: 24px;
}
.wine-infos-1{
    color: #222A46;
    font-size: 15px;
}
.wine-infos-2{
    color: #222A46;
    font-size: 15px;

}
.content-wine-category{
    background-color: #FFF;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px 0;
    max-width: 700px;
    margin: 20px auto 30px auto;
}
.content-wine-category .label-image{
    display: block;
    width: 80%;
    margin: auto;
    padding-bottom: 25px;
}

.content-wine-category .label-image img{
    max-width: 100%;
    margin: auto;
}

.identifier{
    width: 80%;
    margin: auto;
    display: flex;
}
.identifier .caption-image{
    margin-right: 15px;
}
.serial-number-label, .blockchain-label{
    display: block;
    font-size: 12px;
    color: #801744;
    line-height: 20px;
    font-weight: bold;
}
.identifier span{
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
    line-height: 15px;
    word-break: break-all;
}

.contact-box{
    background: #fff;
    margin: 0px 15px 30px 15px; 
    border-radius: 20px;
    padding: 15px;
    text-align: center;
    
}
.contact-box .link{
    color:#4A89DC;

}
.contact-box-bottom{
    display: flex;
    justify-content: center;
    margin-top: 12px;
}
.contact-box-bottom a{
    margin: 11px;
    border-radius: 20px;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,0.11), 0 5px 25px 0 rgba(0,0,0,0.1)!important;
}

.is-infos{
    display: flex;
    justify-content: space-around;
}
.is-infos .info{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.is-infos .link{
    margin-bottom: 5px;
}

.tasting-box{
    background: #fff;
    margin: 0px 15px 30px 15px; 
    border-radius: 20px;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
}
.tasting-infos{
    text-align: left;
    line-height: normal;
    margin-left: 15px;
}

.tasting-infos h5{
    color: #D20C40;
    font-size: 14px;
    font-weight: normal;
}
.tasting-infos p{
    color: #000;
    font-size: 13px;
    margin: 0;
    line-height: 14px;
}
.bt-certificate{
    background: rgb(203,12,60);
    background: linear-gradient(65deg, rgba(203,12,60,1) 13%, rgba(203,94,33,1) 52%, rgba(190,179,17,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:15px;
    color:#fff;
    width: 80%;
    margin: auto;
    padding: 10px;
}

.bt-certificate img{
    filter: brightness(100);
    height: 40px;
    margin-right: 10px;
}


/*---////////// TRACK TRACEABILITY  //////////---*/ 

#track .content-wib {
    margin: 0px 15px 0px 15px;
    background: #fff;
    border-radius:20px;
    display: flex;
}
#track .content-wib img.responsive-image{
   display: none;
}

#track .one-half.small-half .container-wib{
    background: #9195A2;
    border-radius: 100%;
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#track .one-half.small-half .container-wib .font-11 {
    font-size: 11px!important;
    top: auto !important;
    left: auto !important;
    line-height: normal !important;
    text-align:center;
}


#track .one-half.small-half {
    width: 35%!important;
    margin-right: 5%;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
}
#track .center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
#track .under-heading {
    margin-top: -8px;
    display: block;
}
#track .font-12 {
    font-size: 12px!important;
}



/*---/////////////////////////////// DROPDOWNS  //////////---*/ 


.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .dropbtn {
    color: #000;
    font-size: 13px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border:1px solid #ccc;
    padding: 2px 8px;
  }
  
  .dropbtn:hover, .dropbtn:focus {
    background-color: #fff;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
    display: flex;
    align-items: center;
    padding: 2px 24px 2px 4px;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}

.dropbtn .flag{
    margin: 0 2px 0 8px;
    transform: scale(0.8);
}
.dropbtn .arrow{
    position: relative;
    bottom: 2px;
}


.dropdown-content .flag{
    margin: 0 2px 0 8px;
    transform: scale(0.8);
}


.menu-container {
    display: inline-flex;
    flex-direction: row;
    border: solid 2px #000000;
    cursor: pointer;
}

.menu {
    position: relative;
    padding: 0rem 0.5rem;
}

.menu > .menu-content {
    display: none;
    position: absolute;
    border: solid 2px #000000;
}

.menu:focus-within > .menu-content {
    display: flex;
    flex-direction: column;
}

.menu a {
    padding: 0.5rem;
    display: inline-block;
    color: #000000;
}


/*---///////////////////////////////  MEDIA QUERIES  //////////---*/ 

@media only screen and (min-width: 600px)  {

    .footer-menu span{
        font-size: 16px;
    }

}



/*---///////////////////////////////  SPRITE FLAGS //////////---*/ 


.flag { width: 24px; height: 24px; background: url(./assets/images/flags.png) no-repeat; display: inline-block; }
.flag.ad { background-position: 0 0 }
.flag.ae { background-position: -24px 0 }
.flag.af { background-position: -48px 0 }
.flag.ag { background-position: -72px 0 }
.flag.ai { background-position: -96px 0 }
.flag.al { background-position: -120px 0 }
.flag.am { background-position: -144px 0 }
.flag.an { background-position: -168px 0 }
.flag.ao { background-position: -192px 0 }
.flag.aq { background-position: -216px 0 }
.flag.ar { background-position: -240px 0 }
.flag.as { background-position: -264px 0 }
.flag.at { background-position: -288px 0 }
.flag.au { background-position: -312px 0 }
.flag.aw { background-position: -336px 0 }
.flag.ax { background-position: -360px 0 }
.flag.az { background-position: 0 -24px }
.flag.ba { background-position: -24px -24px }
.flag.bb { background-position: -48px -24px }
.flag.bd { background-position: -72px -24px }
.flag.be { background-position: -96px -24px }
.flag.bf { background-position: -120px -24px }
.flag.bg { background-position: -144px -24px }
.flag.bh { background-position: -168px -24px }
.flag.bi { background-position: -192px -24px }
.flag.bj { background-position: -216px -24px }
.flag.bl { background-position: -240px -24px }
.flag.bm { background-position: -264px -24px }
.flag.bn { background-position: -288px -24px }
.flag.bo { background-position: -312px -24px }
.flag.br { background-position: -336px -24px }
.flag.bs { background-position: -360px -24px }
.flag.bt { background-position: 0 -48px }
.flag.bw { background-position: -24px -48px }
.flag.by { background-position: -48px -48px }
.flag.bz { background-position: -72px -48px }
.flag.ca { background-position: -96px -48px }
.flag.cc { background-position: -120px -48px }
.flag.cd { background-position: -144px -48px }
.flag.cf { background-position: -168px -48px }
.flag.cg { background-position: -192px -48px }
.flag.ch { background-position: -216px -48px }
.flag.ci { background-position: -240px -48px }
.flag.ck { background-position: -264px -48px }
.flag.cl { background-position: -288px -48px }
.flag.cm { background-position: -312px -48px }
.flag.cn { background-position: -336px -48px }
.flag.co { background-position: -360px -48px }
.flag.cr { background-position: 0 -72px }
.flag.cu { background-position: -24px -72px }
.flag.cv { background-position: -48px -72px }
.flag.cw { background-position: -72px -72px }
.flag.cx { background-position: -96px -72px }
.flag.cy { background-position: -120px -72px }
.flag.cz { background-position: -144px -72px }
.flag.de { background-position: -168px -72px }
.flag.dj { background-position: -192px -72px }
.flag.dk { background-position: -216px -72px }
.flag.dm { background-position: -240px -72px }
.flag.do { background-position: -264px -72px }
.flag.dz { background-position: -288px -72px }
.flag.ec { background-position: -312px -72px }
.flag.ee { background-position: -336px -72px }
.flag.eg { background-position: -360px -72px }
.flag.eh { background-position: 0 -96px }
.flag.er { background-position: -24px -96px }
.flag.es { background-position: -48px -96px }
.flag.et { background-position: -72px -96px }
.flag.eu { background-position: -96px -96px }
.flag.fi { background-position: -120px -96px }
.flag.fj { background-position: -144px -96px }
.flag.fk { background-position: -168px -96px }
.flag.fm { background-position: -192px -96px }
.flag.fo { background-position: -216px -96px }
.flag.fr { background-position: -240px -96px }
.flag.ga { background-position: -264px -96px }
.flag.gb { background-position: -288px -96px }
.flag.gd { background-position: -312px -96px }
.flag.ge { background-position: -336px -96px }
.flag.gg { background-position: -360px -96px }
.flag.gh { background-position: 0 -120px }
.flag.gi { background-position: -24px -120px }
.flag.gl { background-position: -48px -120px }
.flag.gm { background-position: -72px -120px }
.flag.gn { background-position: -96px -120px }
.flag.gq { background-position: -120px -120px }
.flag.gr { background-position: -144px -120px }
.flag.gs { background-position: -168px -120px }
.flag.gt { background-position: -192px -120px }
.flag.gu { background-position: -216px -120px }
.flag.gw { background-position: -240px -120px }
.flag.gy { background-position: -264px -120px }
.flag.hk { background-position: -288px -120px }
.flag.hn { background-position: -312px -120px }
.flag.hr { background-position: -336px -120px }
.flag.ht { background-position: -360px -120px }
.flag.hu { background-position: 0 -144px }
.flag.ic { background-position: -24px -144px }
.flag.id { background-position: -48px -144px }
.flag.ie { background-position: -72px -144px }
.flag.il { background-position: -96px -144px }
.flag.im { background-position: -120px -144px }
.flag.in { background-position: -144px -144px }
.flag.iq { background-position: -168px -144px }
.flag.ir { background-position: -192px -144px }
.flag.is { background-position: -216px -144px }
.flag.it { background-position: -240px -144px }
.flag.je { background-position: -264px -144px }
.flag.jm { background-position: -288px -144px }
.flag.jo { background-position: -312px -144px }
.flag.jp { background-position: -336px -144px }
.flag.ke { background-position: -360px -144px }
.flag.kg { background-position: 0 -168px }
.flag.kh { background-position: -24px -168px }
.flag.ki { background-position: -48px -168px }
.flag.km { background-position: -72px -168px }
.flag.kn { background-position: -96px -168px }
.flag.kp { background-position: -120px -168px }
.flag.kr { background-position: -144px -168px }
.flag.kw { background-position: -168px -168px }
.flag.ky { background-position: -192px -168px }
.flag.kz { background-position: -216px -168px }
.flag.la { background-position: -240px -168px }
.flag.lb { background-position: -264px -168px }
.flag.lc { background-position: -288px -168px }
.flag.li { background-position: -312px -168px }
.flag.lk { background-position: -336px -168px }
.flag.lr { background-position: -360px -168px }
.flag.ls { background-position: 0 -192px }
.flag.lt { background-position: -24px -192px }
.flag.lu { background-position: -48px -192px }
.flag.lv { background-position: -72px -192px }
.flag.ly { background-position: -96px -192px }
.flag.ma { background-position: -120px -192px }
.flag.mc { background-position: -144px -192px }
.flag.md { background-position: -168px -192px }
.flag.me { background-position: -192px -192px }
.flag.mf { background-position: -216px -192px }
.flag.mg { background-position: -240px -192px }
.flag.mh { background-position: -264px -192px }
.flag.mk { background-position: -288px -192px }
.flag.ml { background-position: -312px -192px }
.flag.mm { background-position: -336px -192px }
.flag.mn { background-position: -360px -192px }
.flag.mo { background-position: 0 -216px }
.flag.mp { background-position: -24px -216px }
.flag.mq { background-position: -48px -216px }
.flag.mr { background-position: -72px -216px }
.flag.ms { background-position: -96px -216px }
.flag.mt { background-position: -120px -216px }
.flag.mu { background-position: -144px -216px }
.flag.mv { background-position: -168px -216px }
.flag.mw { background-position: -192px -216px }
.flag.mx { background-position: -216px -216px }
.flag.my { background-position: -240px -216px }
.flag.mz { background-position: -264px -216px }
.flag.na { background-position: -288px -216px }
.flag.nc { background-position: -312px -216px }
.flag.ne { background-position: -336px -216px }
.flag.nf { background-position: -360px -216px }
.flag.ng { background-position: 0 -240px }
.flag.ni { background-position: -24px -240px }
.flag.nl { background-position: -48px -240px }
.flag.no { background-position: -72px -240px }
.flag.np { background-position: -96px -240px }
.flag.nr { background-position: -120px -240px }
.flag.nu { background-position: -144px -240px }
.flag.nz { background-position: -168px -240px }
.flag.om { background-position: -192px -240px }
.flag.pa { background-position: -216px -240px }
.flag.pe { background-position: -240px -240px }
.flag.pf { background-position: -264px -240px }
.flag.pg { background-position: -288px -240px }
.flag.ph { background-position: -312px -240px }
.flag.pk { background-position: -336px -240px }
.flag.pl { background-position: -360px -240px }
.flag.pn { background-position: 0 -264px }
.flag.pr { background-position: -24px -264px }
.flag.ps { background-position: -48px -264px }
.flag.pt { background-position: -72px -264px }
.flag.pw { background-position: -96px -264px }
.flag.py { background-position: -120px -264px }
.flag.qa { background-position: -144px -264px }
.flag.ro { background-position: -168px -264px }
.flag.rs { background-position: -192px -264px }
.flag.ru { background-position: -216px -264px }
.flag.rw { background-position: -240px -264px }
.flag.sa { background-position: -264px -264px }
.flag.sb { background-position: -288px -264px }
.flag.sc { background-position: -312px -264px }
.flag.sd { background-position: -336px -264px }
.flag.se { background-position: -360px -264px }
.flag.sg { background-position: 0 -288px }
.flag.sh { background-position: -24px -288px }
.flag.si { background-position: -48px -288px }
.flag.sk { background-position: -72px -288px }
.flag.sl { background-position: -96px -288px }
.flag.sm { background-position: -120px -288px }
.flag.sn { background-position: -144px -288px }
.flag.so { background-position: -168px -288px }
.flag.sr { background-position: -192px -288px }
.flag.ss { background-position: -216px -288px }
.flag.st { background-position: -240px -288px }
.flag.sv { background-position: -264px -288px }
.flag.sy { background-position: -288px -288px }
.flag.sz { background-position: -312px -288px }
.flag.tc { background-position: -336px -288px }
.flag.td { background-position: -360px -288px }
.flag.tf { background-position: 0 -312px }
.flag.tg { background-position: -24px -312px }
.flag.th { background-position: -48px -312px }
.flag.tj { background-position: -72px -312px }
.flag.tk { background-position: -96px -312px }
.flag.tl { background-position: -120px -312px }
.flag.tm { background-position: -144px -312px }
.flag.tn { background-position: -168px -312px }
.flag.to { background-position: -192px -312px }
.flag.tr { background-position: -216px -312px }
.flag.tt { background-position: -240px -312px }
.flag.tv { background-position: -264px -312px }
.flag.tw { background-position: -288px -312px }
.flag.tz { background-position: -312px -312px }
.flag.ua { background-position: -336px -312px }
.flag.ug { background-position: -360px -312px }
.flag.us { background-position: 0 -336px }
.flag.uy { background-position: -24px -336px }
.flag.uz { background-position: -48px -336px }
.flag.va { background-position: -72px -336px }
.flag.vc { background-position: -96px -336px }
.flag.ve { background-position: -120px -336px }
.flag.vg { background-position: -144px -336px }
.flag.vi { background-position: -168px -336px }
.flag.vn { background-position: -192px -336px }
.flag.vu { background-position: -216px -336px }
.flag.wf { background-position: -240px -336px }
.flag.ws { background-position: -264px -336px }
.flag.ye { background-position: -288px -336px }
.flag.yt { background-position: -312px -336px }
.flag.za { background-position: -336px -336px }
.flag.zm { background-position: -360px -336px }
.flag.zw { background-position: 0 -360px }
